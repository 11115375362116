export default [
  {
    title: 'Dashboard',
    route: 'dashboard-ecommerce',
    icon: 'HomeIcon',
    role: ['super-admin', 'finance-supervisor', 'admin', 'finance','executive', 'branch', 'head-office'],
  },
  {
    title: 'User',
    route: 'apps-users-list',
    icon: 'UserIcon',
    role: ['super-admin', 'admin', 'head-office'],
  },
  {
    title: 'Branch',
    route: 'apps-branches-list',
    icon: 'ArchiveIcon',
    role: ['super-admin', 'admin', 'head-office'],
  },
  {
    title: 'Role',
    route: 'apps-roles-list',
    icon: 'ShieldIcon',
    role: ['super-admin'],
  },
  {
    title: 'Contracts',
    route: 'apps-contracts-list',
    icon: 'FileIcon',
    role: ['super-admin','legal'],
  },
  {
    title: 'Invoice',
    icon: 'FileTextIcon',
    role: ['super-admin', 'finance-supervisor', 'admin', 'finance','executive', 'branch', 'head-office'],
    children: [
      {
        title: 'Internal',
        route: 'apps-invoices-internal',
        role: ['super-admin', 'finance-supervisor', 'admin', 'finance','executive', 'branch', 'head-office'],
      },
      {
        title: 'Local',
        route: 'apps-invoices-local',
        role: ['super-admin', 'finance-supervisor', 'admin', 'finance','executive', 'branch', 'head-office'],
      },
      {
        title: 'Corporate',
        route: 'apps-invoices-corporate',
        role: ['super-admin', 'finance-supervisor', 'admin', 'finance','executive', 'branch', 'head-office'],
      },
      {
        title: 'Void',
        route: 'apps-invoices-void',
        role: ['super-admin', 'finance-supervisor', 'admin', 'finance','executive', 'branch', 'head-office'],
      },
    ],
  },
  {
    title: 'Income',
    icon: 'DollarSignIcon',
    role: ['super-admin', 'executive'],
    children: [
      {
        title: 'External (All)',
        route: 'apps-income-external-all',
        role: ['super-admin', 'executive'],
      },
      {
        title: 'Corporate',
        route: 'apps-income-corporate',
        role: ['super-admin', 'executive'],
      },
      {
        title: 'Local',
        route: 'apps-income-local',
        role: ['super-admin', 'executive'],
      },
      {
        title: 'Internal',
        route: 'apps-income-internal',
        role: ['super-admin', 'executive'],
      },
      {
        title: 'External (Nett)',
        route: 'apps-income-external-nett',
        role: ['super-admin', 'executive'],
      },
    ],
  },
  {
    title: 'Void',
    route: 'apps-void',
    icon: 'FileIcon',
    role: ['super-admin','admin'],
  },
  {
    title: 'Settings',
    route: 'apps-settings',
    icon: 'SettingsIcon',
    role: ['super-admin'],
  },
  {
    title: 'Purchase Order',
    route: 'apps-procurement-purchase-order-list',
    icon: 'ShoppingCartIcon',
    role: ['super-admin','procurement'],
  },
  {
    title: 'Permintaan Barang',
    route: 'apps-ro',
    icon: 'ArrowDownCircleIcon',
    role: ['super-admin','procurement'],
  },
]
